import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="utils--expand-details"
export default class extends Controller {
  static targets = ['expandOnShow']

  connect() {
    this.expandOnShowTargets.forEach(target => {
      target.classList.add('collapse')
    })
  }

  toggle() {
    this.expandOnShowTargets.forEach(target => {
      target.classList.toggle('show')
    })
  }
}

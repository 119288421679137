import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="site-task-measurements--site-draw"
export default class extends Controller {
  static values = {
    formId: String,
  }
  // `showInModalOnly` - if false will hide contained elements when page is opened in new tab
  static targets = ['showInModalOnly']

  connect() {
    this.drawingModalEl = document.getElementById('drawingModal')

    if (!this.drawingModalEl) {
      this.showInModalOnlyTarget.classList.add('d-none')
    }
  }

  disconnect() {
    window.modal?.hide()
  }

  insertTotal() {
    if (this.drawingModalEl) {
      const totalEl = document.getElementById(`measure_total_${this.formIdValue}`)
      totalEl.value = document.querySelector('[name="drawing[total]"]').value
      totalEl.dispatchEvent(new Event('input', { bubbles: true }));

      document.getElementById('drawingModal').remove()
    }
  }
}

import i18next from 'i18next'
import { Controller } from '@hotwired/stimulus'

import { debounce } from '../../utils/timeUtils'
import { TirinnovoDrawing } from './lib/tirinnovo_drawing'
import { ApiService } from '../../services/apiService'
import { lazyI18n } from '../../utils/lazyI18n'

// Connects to data-controller="draw--form"
export default class extends Controller {
  static values = {
    siteId: String,
  }
  static targets = [
    'blueprintTitle',
    'drawingBlueprintId', 'drawingId', 'drawingShapes', 'referenceValue', 'total', 'totalSelected',
    'drawingTitleDisplay', 'drawingTitleInput',
  ]

  connect() {
    this.blueprint = {
      file: null,
      title: this.blueprintTitleTarget.value,
      reference_line: [{ x: 100, y: 100 }, { x: 200, y: 100 }],
      reference_value: this.referenceValueTarget.value,
    }
    this.drawer = null
    this.launchDrawingBoard()
    this.debouncedUpdateRemoteBlueprint = debounce(this.updateRemoteBlueprint.bind(this), 100)

    this.showDrawingTitleDisplay()
    this.drawingTitleInputTarget.addEventListener('blur', this.onUpdateDrawingTitleRequest.bind(this));
  }

  disconnect() {
    this.drawingTitleInputTarget.removeEventListener('blur', this.onUpdateDrawingTitleRequest.bind(this));
  }


  switchDrawingType(e) {
    this.drawer.switchDrawingType(e.target.value)
  }

  updateBackgroundOpacityChange(e) {
    this.drawer.updateBackgroundOpacity(e.target.value)
  }

  updateReferenceValue(e) {
    const value = e.target.value
    this.blueprint.reference_value = value
    this.drawer.updateReferenceValue(value)
    this.debouncedUpdateRemoteBlueprint()
  }

  onUpdateReferencePoints(points) {
    this.blueprint.reference_points = points
    this.updateRemoteBlueprint()
  }

  onUpdatePoints(shapes) {
    const updatedShapes = JSON.stringify(shapes)
    if (this.drawingShapesTarget.value != updatedShapes) {
      this.drawingShapesTarget.value = updatedShapes
      this.saveDrawing()
    }
  }

  onTotalChange({ total, totalSelected }) {
    const output = {
      total: (total || 0).toFixed(2),
      totalSelected: (totalSelected || 0).toFixed(2),
    }
    this.totalTarget.value = output.total
    this.totalSelectedTarget.innerHTML = output.totalSelected > 0 ? output.totalSelected : ''
  }

  resetViewClick() {
    this.drawer.resetView()
  }

  async handleFile(event) {
    const file = event.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append("blueprint[file]", file)
    formData.append("blueprint[site_id]", this.siteIdValue)
    const blueprint = await ApiService.post(`/sites/${this.siteIdValue}/blueprints`, formData, {})
    this.blueprint = blueprint

    fetch(`/sites/${this.siteIdValue}/blueprints`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  // ??? what is this?!
  // To know when `renderStreamMessage` has finished, we don't want to update the title too early
  // titleTargetConnected() {
  //   if (this.blueprint) {
  //     this.setBlueprint(this.blueprint)
  //   }
  // }

  async selectBlueprint(event) {
    const blueprint = JSON.parse(event.target.dataset.blueprint)
    document.getElementById('loading-overlay').classList.remove('d-none')
    const fileResponse = await fetch(blueprint.file_url)
    const drawings = await ApiService.get(`/sites/${this.siteIdValue}/drawings.json?blueprint_id=${blueprint.id}&per_page=1`)
    document.getElementById('loading-overlay').classList.add('d-none')

    const firstDrawing = drawings?.drawings?.[0] || {
      drawing_type: 'shape',
      blueprint_id: null,
      title: null,
      shapes: [],
      total: 0,
    }
    this.setBlueprint(blueprint, fileResponse, firstDrawing)
  }

  async setBlueprint(blueprint, fileResponse, firstDrawing) {
    this.blueprint = blueprint
    this.drawingBlueprintIdTarget.value = blueprint.id
    this.blueprintTitleTarget.innerHTML = blueprint.title
    this.referenceValueTarget.value = blueprint.reference_value

    this.drawer.loadBlueprint({
      file: await fileResponse.blob(),
      reference_points: blueprint.reference_points,
      reference_value: blueprint.reference_value,
    })

    this.loadDrawing(firstDrawing)
  }

  launchDrawingBoard() {
    this.drawer = new TirinnovoDrawing({
      drawType: 'area',
      blueprint: this.blueprint,
      onTotalChange: this.onTotalChange.bind(this),
      onUpdateReferencePoints: debounce(this.onUpdateReferencePoints.bind(this), 100),
      onUpdatePoints: debounce(this.onUpdatePoints.bind(this), 100),
    })
  }

  async editBlueprintClick(event) {
    const title = prompt(lazyI18n('Inserisci il nuovo nome della piantina'))
    if (title) {
      const blueprintId = event.currentTarget.dataset.blueprint_id
      await ApiService.patch(`/sites/${this.siteIdValue}/blueprints/${blueprintId}`, {
        blueprint: {
          title: title,
        }
      })
    }
  }

  async updateRemoteBlueprint() {
    if (!this.blueprint.id) { return }
    await ApiService.patch(`/sites/${this.siteIdValue}/blueprints/${this.blueprint.id}`, this.blueprint)
  }

  async saveDrawing() {
    const formData = Object.fromEntries(new FormData(document.getElementById('drawing_form')))
    formData['drawing[shapes]'] = JSON.parse(formData['drawing[shapes]'] || '[]')
    console.log('drawing', formData);

    // Should probably use a socket to minimize time
    const savedDrawing = await ApiService.post(`/sites/${this.siteIdValue}/drawings`, {
      authenticity_token: formData.authenticity_token,
      drawing: {
        id: formData['drawing[id]'],
        blueprint_id: formData['drawing[blueprint_id]'],
        shapes: formData['drawing[shapes]'],
        drawing_type: formData['drawing[drawing_type]'],
        total: formData['drawing[total]'],
        title: formData['drawing[title]'],
      },
    })
    console.log('savedDrawing', savedDrawing);
    this.drawingIdTarget.value = savedDrawing.id;
  }

  // drawing title ---------
  onDrawingTitleInputKeydown(event) {
    if (event.key === 'Enter') {
      this.drawingTitleInputTarget.blur()
    }
  }

  editDrawingTitle() {
    this.drawingTitleDisplayTarget.classList.add('d-none')
    this.drawingTitleInputTarget.classList.remove('d-none')
    this.drawingTitleInputTarget.innerHTML = this.drawingTitleDisplayTarget.value
    this.drawingTitleInputTarget.focus()
    this.drawingTitleInputTarget.select()
  }

  showDrawingTitleDisplay() {
    this.drawingTitleInputTarget.classList.add('d-none')
    this.drawingTitleDisplayTarget.innerHTML = this.drawingTitleInputTarget.value
    this.drawingTitleDisplayTarget.classList.remove('d-none')
  }

  onUpdateDrawingTitleRequest() {
    this.showDrawingTitleDisplay()
    this.saveDrawing()
  }
  // drawing title ---------

  loadDrawing(drawing) {
    this.drawingIdTarget.value = drawing.id,
    this.blueprintTitleTarget.value = drawing.title
    this.drawingTitleDisplayTarget.innerHTML = drawing.title
    this.drawingShapesTarget.value = JSON.stringify(drawing.shapes)
    this.drawer.loadShapes(drawing.shapes)
  }
}
